@import url(https://fonts.googleapis.com/css?family=Merriweather&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mjx-chtml {
  pointer-events: none; 
}

#MJXc-Node-66 {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  font-size: 18px;
  font-weight: bold;
}

p {
  line-height: 1.7em;
}

.textstyle_container__1gk0k{padding-top:1em;padding-bottom:3em}.textstyle_container__1gk0k>p>a{color:black;border-bottom:1px dotted;text-decoration:none}.textstyle_container__1gk0k>p>a:hover{border-bottom:1px solid}.textstyle_container__1gk0k>p>ul>li>a{color:black;border-bottom:1px dotted;text-decoration:none}.textstyle_container__1gk0k>p>ul>li>a:hover{border-bottom:1px solid}

/* main color: rgb(12, 110, 86)*/

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.info-content {
  padding-top:8px;
  padding-bottom: 30px;
}

/*//////////////////////////////////////////////////////////////////////
/////////////////////////// Calculator CSS /////////////////////////////
//////////////////////////////////////////////////////////////////////*/
.calc-wrapper {
  width: 100vw;
  height: 100vh;
  margin-top: -32px;

}

.fix-calculator{
  padding-bottom: 1.5rem;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .fix-calculator{
    position: static;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .fix-calculator{
    position: fixed;
    width: 330px;
    padding-bottom: 0;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .fix-calculator{
    position: fixed;
    width: 370px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
.fix-calculator{
  position: fixed;
  width: 445px;
}
}

.material-button {
  height: 40px;
  color: white;
  background-color: rgb(14, 153, 119);
  border-radius: 2px;
  box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
  border: none;
  padding: 2px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0;
  margin-right: 5px;
  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.material-button:hover {
  background-color: rgb(13, 122, 95);
}

.material-button:active {
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
  border: none;
}

.material-button:focus {
  outline: 0;
}

.calculator-button {
  margin: 10px 0px;
  border-radius: 2px;
  background:  rgb(224, 224, 224);
  box-shadow: 0px 0px 3px 0px rgba(95, 95, 95, 0.68);
  overflow: hidden;
}

.input-box {
  margin: 0px 0px 10px 0px;
  border-radius: 2px;
  background:  rgb(224, 224, 224);
  box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 2px 1px -2px rgba(0,0,0,0.12);
  overflow: hidden;
}

.input-header {
  display: flex;
  height: 50px;
  flex: 1 1;
  align-items: center;
  justify-content: space-between;
  padding-right: 8px;
  padding-left: 16px;
  background: rgb(224, 224, 224);
}

.input-header-name {
  margin-right: 10px;
  color: rgb(32, 32, 32);
  cursor: default;
}

.input-line {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  cursor: pointer;
  background: rgb(255, 255, 255);
  border-top: 1px solid;
  border-color: rgb(224, 224, 224);
}

.input-line-box {
  display: flex;
  height: 50px;
  flex: 1 1;
  align-items: center;
  justify-content: space-between;
  margin-right: 8px;
  margin-left: 16px;
}

.input-line-name {
  margin-right: 10px;
  color: #555;
  cursor: pointer;
}

.input-line input {
  width: 60px;
  height: 48px;
  flex: auto;
  font-size: 24px;
  line-height: 48px;
  text-align: right;
  background: 0 0;
  border: 0;
  outline: 0;
}

.input-line-unit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 8px;
  color: #555;
}
.notfound_content__3Eo_9{color:#b4b4b4;font-size:12pt;min-height:100%;display:flex;flex-direction:column;align-items:center;justify-content:center;text-align:center}.notfound_title__1xcu_{font-size:80pt;font-weight:bold;line-height:1}.notfound_text__1asXB{padding-bottom:5em}@media (min-width: 768px){.notfound_content__3Eo_9{font-size:15pt}.notfound_title__1xcu_{font-size:200pt}}

.copyright_copyright__zyCHp{height:100%;width:100%;text-align:center;color:#b4b4b4;font-size:10px;padding:13px 3px;background:#3a3a3a;box-shadow:inset 0px 184px 14px -190px rgba(0,0,0,0.75)}.copyright_copyright__zyCHp a{color:#b4b4b4;text-decoration:none}.copyright_copyright__zyCHp a:hover{color:#fff;text-decoration:none}

.footer_footer__2g_F7{height:100%;width:100%;padding:40px 15px;background:linear-gradient(53deg, #10d6a5, #0c6952);background-size:100% 100%;box-shadow:inset 0px 184px 14px -190px rgba(0,0,0,0.75);color:white}.footer_logo__2ueIU>img{width:100%}.footer_column__2gUQB{padding-bottom:3em}.footer_desktopLinks__2rwgp{display:none}.footer_desktopLinks__2rwgp>p>a,.footer_mobileLinks__34JZ1>a{color:white}.footer_desktopLinks__2rwgp>p>a:hover,.footer_mobileLinks__34JZ1>a:hover{color:#ebebeb;text-decoration:none}.footer_mobileLinks__34JZ1{width:100%;display:flex;flex-direction:row;flex-wrap:wrap;justify-content:space-between}@media (min-width: 768px){.footer_footer__2g_F7{padding:60px 15px}.footer_mobileLinks__34JZ1{display:none}.footer_desktopLinks__2rwgp{display:block}.footer_column__2gUQB{padding-bottom:0}.footer_logo__2ueIU>img{height:150px;width:auto}}

.sharebutton_button__3Gvn4{width:32px;height:32px;color:#0e9977;margin:4px 5px 4px 0px;float:left;border-style:solid;border-color:#0e9977;border-width:1px;border-radius:50%;display:flex;align-items:center;justify-content:center;font-size:20px}.sharebutton_button__3Gvn4:hover{transform:scale(1.08)}.sharebutton_button__3Gvn4:active{border-color:#505050;color:#505050}.sharebutton_button__3Gvn4 p{margin:0}

.socialbox_wrapper__24HqQ{height:40px;color:#0e9977}.socialbox_text__oElY5{height:32px;margin:4px 9px 4px 0px;float:left;text-align:center;vertical-align:middle;line-height:32px}.socialbox_container__3Bi0X{float:left;width:111px}

.navbar_title__aPYF8{font-size:20pt;font-family:'Montserrat', sans-serif;font-weight:550;color:#0e9977;line-height:40px;margin-right:0em}.navbar_content__2VL1f{display:flex;flex-direction:row;flex-wrap:wrap;justify-content:space-between}.navbar_navbar__3DvWZ{padding-top:24px;padding-bottom:24px;background-color:#fff}.navbar_backlink__31s2y>a{font-weight:600;color:#0e9977;text-decoration:none;align-items:center}.navbar_backlink__31s2y>a:hover{font-weight:600;color:#0c8365;text-decoration:none}@media (min-width: 424px){.navbar_title__aPYF8{font-size:24pt}}@media (min-width: 576px){.navbar_title__aPYF8{font-size:28pt}}

