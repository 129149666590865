.calculator-button {
  margin: 10px 0px;
  border-radius: 2px;
  background:  rgb(224, 224, 224);
  -webkit-box-shadow: 0px 0px 3px 0px rgba(95, 95, 95, 0.68);
  -moz-box-shadow: 0px 0px 3px 0px rgba(95, 95, 95, 0.68);
  box-shadow: 0px 0px 3px 0px rgba(95, 95, 95, 0.68);
  overflow: hidden;
}

.input-box {
  margin: 0px 0px 10px 0px;
  border-radius: 2px;
  background:  rgb(224, 224, 224);
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 2px 1px -2px rgba(0,0,0,0.12);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 2px 1px -2px rgba(0,0,0,0.12);
  box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 2px 1px -2px rgba(0,0,0,0.12);
  overflow: hidden;
}

.input-header {
  display: flex;
  height: 50px;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding-right: 8px;
  padding-left: 16px;
  background: rgb(224, 224, 224);
}

.input-header-name {
  margin-right: 10px;
  color: rgb(32, 32, 32);
  cursor: default;
}

.input-line {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  cursor: pointer;
  background: rgb(255, 255, 255);
  border-top: 1px solid;
  border-color: rgb(224, 224, 224);
}

.input-line-box {
  display: flex;
  height: 50px;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin-right: 8px;
  margin-left: 16px;
}

.input-line-name {
  margin-right: 10px;
  color: #555;
  cursor: pointer;
}

.input-line input {
  width: 60px;
  height: 48px;
  flex: auto;
  font-size: 24px;
  line-height: 48px;
  text-align: right;
  background: 0 0;
  border: 0;
  outline: 0;
}

.input-line-unit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 8px;
  color: #555;
}