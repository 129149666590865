@import 'layout';

.copyright {
  height: 100%;
  width: 100%;
  text-align: center;
  color: rgb(180, 180, 180);
  font-size: 10px;
  padding: 13px 3px;
  background: rgb(58, 58, 58);
  -webkit-box-shadow: inset 0px 184px 14px -190px rgba(0,0,0,0.75);
  -moz-box-shadow: inset 0px 184px 14px -190px rgba(0,0,0,0.75);
  box-shadow: inset 0px 184px 14px -190px rgba(0,0,0,0.75);
}

.copyright a {
  color: rgb(180, 180, 180);
  text-decoration: none;
}

.copyright a:hover {
  color: rgb(255, 255, 255);
  text-decoration: none;
}