.button {
  width: 32px ;
  height: 32px;
  color: rgb(14, 153, 119);
  margin: 4px 5px 4px 0px;
  float: left;
  border-style: solid;
  border-color: rgb(14, 153, 119);
  border-width: 1px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.button:hover {
  transform: scale(1.08);
}

.button:active {
  border-color: rgb(80, 80, 80);
  color: rgb(80, 80, 80);
}

.button p {
  margin: 0;
}