@import '/../components/layout/layout';

.content {
  color: $grey;
  font-size: 12pt;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.title {
  font-size: 80pt;
  font-weight: bold;
  line-height: 1;
}

.text {
  padding-bottom: 5em;
}

@media (min-width: $breakpoint-large-phone) {

}

@media (min-width: $breakpoint-tablet) {
  .content {
    font-size: 15pt;
  }
  
  .title {
    font-size: 200pt;
  }
}

@media (min-width: $breakpoint-laptop) {
  
}

@media (min-width: $breakpoint-desktop) {
  
}

