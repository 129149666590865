@import '/../components/layout/layout';

.container {
  padding-top: 1em;
  padding-bottom: 3em;
}

.container > p > a {
  color: black;
  border-bottom: 1px dotted;
  text-decoration: none;
}

.container > p > a:hover {
  border-bottom: 1px solid;
}

.container > p > ul > li > a {
  color: black;
  border-bottom: 1px dotted;
  text-decoration: none;
}

.container > p > ul > li > a:hover {
  border-bottom: 1px solid;
}