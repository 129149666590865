/* main color: rgb(12, 110, 86)*/

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.info-content {
  padding-top:8px;
  padding-bottom: 30px;
}

/*//////////////////////////////////////////////////////////////////////
/////////////////////////// Calculator CSS /////////////////////////////
//////////////////////////////////////////////////////////////////////*/
.calc-wrapper {
  width: 100vw;
  height: 100vh;
  margin-top: -32px;

}

.fix-calculator{
  padding-bottom: 1.5rem;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .fix-calculator{
    position: static;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .fix-calculator{
    position: fixed;
    width: 330px;
    padding-bottom: 0;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .fix-calculator{
    position: fixed;
    width: 370px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
.fix-calculator{
  position: fixed;
  width: 445px;
}
}
