.wrapper {
  height: 40px;
  color: rgb(14, 153, 119);
}

.text {
  height: 32px;
  margin: 4px 9px 4px 0px;
  float: left;
  text-align: center;
  vertical-align: middle;
  line-height: 32px;
}

.container {
  float: left;
  width: 111px;
}