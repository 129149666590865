@import 'layout';

.footer {
  height: 100%;
  width: 100%;
  padding: 40px 15px;
  background: linear-gradient(53deg, rgb(16, 214, 165), rgb(12, 105, 82));
  background-size: 100% 100%;
  -webkit-box-shadow: inset 0px 184px 14px -190px rgba(0,0,0,0.75);
  -moz-box-shadow: inset 0px 184px 14px -190px rgba(0,0,0,0.75);
  box-shadow: inset 0px 184px 14px -190px rgba(0,0,0,0.75);
  color: white;
}



.logo > img {
  width: 100%;
}

.column {
  padding-bottom: 3em;
}

.desktopLinks {
  display: none;
}
.desktopLinks > p > a,
.mobileLinks > a {
  color: white;
}
.desktopLinks > p > a:hover,
.mobileLinks > a:hover {
  color: rgb(235, 235, 235);
  text-decoration: none;
}

.mobileLinks {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}


@media (min-width: $breakpoint-large-phone) {

}

@media (min-width: $breakpoint-tablet) {
  .footer {
    padding: 60px 15px;
  }
  
  .mobileLinks {
    display: none;
  }
  
  .desktopLinks {
    display: block;
  }

  .column {
    padding-bottom: 0;
  }

  .logo > img {
    height: 150px;
    width: auto;
  }
}

@media (min-width: $breakpoint-laptop) {
  
}

@media (min-width: $breakpoint-desktop) {
  
}


