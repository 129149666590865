@import 'layout';

.title {
  font-size: 20pt;
  font-family: 'Montserrat', sans-serif;
  font-weight: 550;
  color: rgb(14, 153, 119);
  line-height: 40px; 
  margin-right: 0em;
}

.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.navbar {
  padding-top: 24px;
  padding-bottom: 24px;
  background-color: rgb(255, 255, 255);
}

.backlink > a{
  font-weight: 600;
  color: rgb(14, 153, 119);
  text-decoration: none;
  align-items: center;
}

.backlink > a:hover{
  font-weight: 600;
  color: rgb(12, 131, 101);
  text-decoration: none;
}

@media (min-width: 424px) {
  .title {
    font-size: 24pt;
  }
}

@media (min-width: $breakpoint-large-phone) {
  .title {
    font-size: 28pt;
  }
}

@media (min-width: $breakpoint-tablet) {

}

@media (min-width: $breakpoint-laptop) {
  
}

@media (min-width: $breakpoint-desktop) {
  
}

